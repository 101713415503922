import Vue from 'vue'
import VueRouter from 'vue-router'
import routers from '@/router/routers'
// import { initMenu } from '@/utils/menus'
// import store from '@/store'

Vue.use(VueRouter)

const routes = routers

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (Vue.cookie.get('token')) {
    // 有token
    if (to.path === '/login') {
      // 无需再次登录，跳转首页
      next('/')
    } else {
      // initMenu(router, store)
      // sessionStorage.setItem('current_menu_path', to.path) // 记录目标页path
      // 判断是否存在菜单权限
      let nextFlag = true
      const routes = router.options.routes
      const isAdmin = JSON.parse(sessionStorage.galdym || 'false')
      for (let i = 0; i < routes.length; i++) {
        const childs = routes[i].children || []
        for (let j = 0; j < childs.length; j++) {
          if (childs[j].name === to.name && childs[j].isAdmin !== isAdmin) {
            nextFlag = false
            if (isAdmin) {
              next('/user')
            } else {
              next('/myFolder')
            }
            break
          }
        }
      }

      if (nextFlag) {
        // 如果不是从/myFile跳转/myFolder
        if (to.path === '/myFolder' && from.path !== '/myFile') {
          sessionStorage.removeItem('folderPage')
        }
        // 如果不是从/analysis 或 /analysisMore 跳转/myFile
        if (to.path === '/myFile' && from.path !== '/analysis' && from.path !== '/analysisMore') {
          sessionStorage.removeItem('filePage')
        }
        next()
      }
    }
  } else {
    // 无token, 跳登录页
    if (to.path === '/login') {
      // 避免重复触发
      next()
    } else if (to.path === '/tos') {
      // 避免重复触发
      next()
    } else {
      next('/login')
    }
  }
})

export default router
