import Vue from 'vue'

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  Vue.cookie.delete('token')
}

/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16)
  })
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]].children) {
        temp[data[k][pid]].children = []
      }
      if (!temp[data[k][pid]]._level) {
        temp[data[k][pid]]._level = 1
        temp[data[k][pid]].parentName = ''
      }
      data[k]._level = temp[data[k][pid]]._level + 1
      data[k].parentName = temp[data[k][pid]].name
      temp[data[k][pid]].children.push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 数据分组类groupBy
 * @param {*} array 对象数组
 * @param {*} f 返回对象的某个指定属性的属性值
 */
export function groupBy(array, f) {
  const groups = {}
  array.forEach(function (o) {
    const group = f(o)
    groups[group] = groups[group] || []
    groups[group].push(o)
  })
  return groups
}

/**
 * 时间格式化
 * @param {*} fmt 格式字符串，如yyyy-MM-dd HH:mm:ss
 * @param {*} f 日期
 */
export function dateFormat(fmt, date) {
  let ret
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

/**
 * 函数防抖
 * @param {*} fn [function] 需要防抖的函数
 * @param {*} delay [number] 毫秒，防抖期限值
 */
export const debounce = function (fn, delay) {
  let timer = null // 借助闭包
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(fn, delay)
  }
}

/**
 * 数组去重
 * @param {*} arr [array] 需要去重的数组
 */
export const unique = function (arr) {
  return Array.from(new Set(arr))
}

/**
 * 时间格式化 2021-08-27 14:19:58
 * @param {*} date Date
 */
export function timeFormat(date = new Date()) {
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hour = date.getHours()
  let minute = date.getMinutes()
  let second = date.getSeconds()

  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  hour = hour < 10 ? '0' + hour : hour
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second

  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
}

/**
 * 查找字符
 * @param {*} str 字符串
 * @param {*} cha 字符
 * @param {*} num 第几次出现
 */
export function findStr(str, cha, num) {
  let x = str.indexOf(cha)
  for (let i = 0; i < num; i++) {
    x = str.indexOf(cha, x + 1)
  }
  return x
}

/**
 * 数字按千分位形式显示
 * @param {*}  num 数字字符串
 */
export function changeToThousand(num) {
  let str = num
  if (str && str !== 'null' && str !== '0') {
    str = str.toString().split('').reverse().join('')
    let chatArr = []
    const arr = []

    for (let i = 0; i < str.length; i++) {
      chatArr.push(str[i])
      if ((i && i % 2 === 0) || i + 1 === str.length) {
        arr.push(chatArr.join(''))
        chatArr = []
      }
    }

    str = arr.join().split('').reverse().join('')
  } else {
    str = '0'
  }
  return str
}

/**
 * 手机号强校验
 * @param {*}  phone 手机号
 */
export function regPhone(phone) {
  return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/g.test(phone)
}
