import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAdmin: false,
    routes: [],
    needMsg: Boolean, // 是否需要提示token过期
    uploadList: [], // 待上传列表
    uploadingPnList: {}, // 正在上传或代上传的产品{pn: uid}
    failedPnList: {}, // 已取消或者上传失败的产品{pn: uid}
    unsuccessFileList: [], // 除成功外的任务列表 [name]
    refreshFlag: 0, // 上传后刷新列表标志
    globalCapacity: 0, // 查询容量标志
    authList: [] // 权限key
  },
  mutations: {
    // 同步
    initAdmin(state, data) {
      state.isAdmin = data
    },
    initRoutes(state, data) {
      state.routes = data
    },
    clearRoutes(state) {
      state.routes = []
    },
    changeNeedMsg(state, data) {
      state.needMsg = data
    },
    // 增加上传
    addUpload(state, data) {
      state.uploadList.push(data)
    },
    // 清空上传
    clearUpload(state) {
      state.uploadList = []
    },
    // 更新正在上传的产品
    updateUploadingPnList(state, data) {
      state.uploadingPnList = data
    },
    // 更新已取消或者上传失败的产品
    updateFailedPnList(state, data) {
      state.failedPnList = data
    },
    // 更新除成功外的文件列表
    updateUnsucessFileList(state, data) {
      state.unsuccessFileList = data
    },
    // 更新刷新标识
    addRefresh(state) {
      state.refreshFlag++
    },
    // 更新查询容量标志
    updateCapacity(state) {
      state.globalCapacity++
    },
    // 初始化权限
    initAuth(state, data) {
      state.authList = data
    }
  },
  actions: {
    // 异步
  },
  modules: {}
})
