module.exports = {
  hello: 'hello',
  message: {
    title: 'Sport Brands'
  },
  placeholder: {
    enter: 'Please type in your favorite brand'
  },
  brands: {
    nike: 'Nike',
    adi: 'Adidas',
    nb: 'New Banlance',
    ln: 'LI Ning'
  }
}
