import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookie from 'vue-cookie'
import HttpRequest from '@/utils/httpRequest'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/theme/index.css'
import '@/assets/global.css'
import '@/assets/iconfont/iconfont.css' // iconfont矢量图
import { ellipsis } from '@/filters'
import i18n from '@/i18n'

Vue.use(ElementUI)
Vue.use(VueCookie)
Vue.prototype.$http = HttpRequest
Vue.filter('ellipsis', ellipsis)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
