module.exports = {
  hello: '你好',
  message: {
    title: '运动品牌'
  },
  placeholder: {
    enter: '请输入您喜欢的品牌'
  },
  brands: {
    nike: '耐克',
    adi: '阿迪达斯',
    nb: '新百伦',
    ln: '李宁'
  }
}
