// 一些全局的config配置
const modeUrlObj = {
  // 生产环境
  production: {
    baseURL: '/gateway'
  },
  // 开发环境
  development: {
    baseURL: '/api'
  },
  // 测试环境
  test: {
    baseURL: 'http://172.16.20.101:9900'
  }
}
export default modeUrlObj[process.env.NODE_ENV]
