export default [
  {
    path: '/',
    name: 'Home',
    title: '首页',
    component: () => import('@/views/Home.vue'),
    hidden: true
    // children: [
    //   {
    //     path: '/sys/menu',
    //     name: 'Menu',
    //     title: '菜单管理',
    //     component: () => import('@/views/sys/Menu.vue')
    //   },
    // ]
  },
  {
    path: '/',
    name: 'Top',
    title: '顶级菜单',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/myFolder',
        name: 'MyFolder',
        title: '首页',
        component: () => import('@/views/MyFolder.vue'),
        isAdmin: false
      },
      {
        path: '/shootAndRule',
        name: 'ShootAndRule',
        title: '拍摄与规则集',
        component: () => import('@/views/ShootAndRule.vue'),
        hidden: true,
        isAdmin: false
      },
      {
        path: '/myFile',
        name: 'MyFile',
        title: '我的文件',
        component: () => import('@/views/MyFile.vue'),
        hidden: true,
        isAdmin: false
      },
      {
        path: '/analysis',
        name: 'Analysis',
        title: '单图测温分析',
        component: () => import('@/views/Analysis.vue'),
        hidden: true,
        isAdmin: false
      },
      {
        path: '/analysisMore',
        name: 'AnalysisMore',
        title: '多图测温分析',
        component: () => import('@/views/AnalysisMore.vue'),
        hidden: true,
        isAdmin: false
      },
      {
        path: '/device',
        name: 'Device',
        title: '设备管理',
        component: () => import('@/views/Device.vue'),
        isAdmin: false
      },
      {
        path: '/preview',
        name: 'Preview',
        title: '实时预览',
        component: () => import('@/views/Preview.vue'),
        hidden: true,
        isAdmin: false,
        val: 8 // 用于权限控制
      },
      {
        path: '/recycle',
        name: 'Recycle',
        title: '回收站',
        component: () => import('@/views/Recycle.vue'),
        isAdmin: false
      },
      {
        path: '/user',
        name: 'User',
        title: '用户管理',
        component: () => import('@/views/User.vue'),
        isAdmin: true
      },
      {
        path: '/deviceStat',
        name: 'DeviceStat',
        title: '设备统计',
        component: () => import('@/views/DeviceStat.vue'),
        isAdmin: true
      },
      {
        path: '/productInfo',
        name: 'ProductInfo',
        title: '产品信息',
        component: () => import('@/views/ProductInfo.vue'),
        isAdmin: true
      },
      {
        path: '/releasePackage',
        name: 'ReleasePackage',
        title: '版本固件发布',
        component: () => import('@/views/ReleasePackage.vue'),
        isAdmin: true
      }
    ]
  },
  {
    path: '/tos',
    name: 'TermsOfService',
    title: '服务条款',
    component: () => import('@/views/TermsOfService.vue'),
    hidden: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    hidden: true
  }
  // {
  //   path: '*',
  //   redirect: '/',
  //   hidden: true
  // }
]
