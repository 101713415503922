import Vue from 'vue'
import axios from 'axios'
import { clearLoginInfo } from '@/utils'
import router from '@/router'
import merge from 'lodash/merge'
import qs from 'qs'
import { Loading, Message } from 'element-ui'
import config from '@/config'
import store from '@/store'

// 加载效果
const loading = {
  // 实例
  loadingInstance: null,
  // 打开
  open() {
    if (!this.loadingInstance) {
      // 若为空,则创建
      this.loadingInstance = Loading.service({
        text: '加载中...', // 加载图标下的文字
        spinner: 'el-icon-loading', // 加载图标
        background: 'rgba(0, 0, 0, 0.8)', // 背景色
        customClass: 'loading' // 自定义样式的类名
      })
    }
  },
  // 关闭
  close() {
    // 不为空时, 则关闭加载窗口
    if (this.loadingInstance) {
      this.loadingInstance.close()
    }
    this.loadingInstance = null
  }
}

/**
 * 创建axios实例
 */
const httpRequset = axios.create({
  timeout: 1000 * 30, // 请求超时时间
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'x-app-name': 'web'
  }
})

/**
 * 请求拦截
 */
httpRequset.interceptors.request.use(
  (config) => {
    // loading.open()
    // config配置
    if (config.url.toString().indexOf('api-uaa/oauth/token') < 0) {
      // 跳过登录
      config.headers.Authorization = Vue.cookie.get('token') // 请求头带上token
    }
    // 携带menuId配合后端功能权限校验
    const menuList = JSON.parse(sessionStorage.getItem('current_menuList')) || []
    const path = sessionStorage.getItem('current_menu_path')
    let menuId = ''
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].path === path) {
        menuId = menuList[i].id
        break
      }
    }
    config.headers.menuid = menuId

    return config
  },
  (error) => {
    // 处理出错
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
httpRequset.interceptors.response.use(
  (response) => {
    try {
      loading.close()
      // response正常返回
      const res = response.data
      // 判断是否是文件流
      if (response.headers['content-type'] === 'application/octet-stream') {
        return response
      }
      // 校验token
      if (res && res.resp_code === 1 && res.resp_msg.indexOf('Invalid access token') !== -1) {
        // token 失效
        if (store.state.needMsg) {
          store.commit('changeNeedMsg', false)
          Message.error('登录过期，请重新登录')
          clearLoginInfo()
          router.push('/login')
          // store.commit('clearRoutes')
        }
      }
      return res
    } catch (error) {
      console.log(error)
    }
  },
  (error) => {
    loading.close()

    // 处理response出错
    if (error && error.response) {
      if (error.response.status === 401) {
        if (store.state.needMsg) {
          store.commit('changeNeedMsg', false)
          Message.error('登录过期，请重新登录')
          clearLoginInfo()
          router.push('/login')
          // store.commit('clearRoutes')
        }
      } else {
        Message.error(error.response.data.resp_msg || '服务器开小差了，请稍后再试')
      }
    } else {
      Message.error('连接服务器失败')
    }

    return Promise.reject(error)
  }
)

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
httpRequset.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  return config.baseURL + actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
httpRequset.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    t: new Date().getTime()
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
httpRequset.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    t: new Date().getTime()
  }
  data = openDefultdata ? merge(defaults, data) : data
  if (contentType === 'text') {
    return data
  }
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default httpRequset
